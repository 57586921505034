import AjaxTable from '../helpers/ajaxTable';
import { displayErrorMessage } from '../helpers/displayError';
import { isNullUndefinedEmpty } from '../helpers/helpers';
import userStateManager from '../helpers/stateManager';

export const component = async () => {
    const momentModule = await import('moment-mini');
    window.moment = momentModule.default;

    const $myAwardsTable = $('#my-awards-table');
    const $upcomingAwardsTable = $('#my-upcoming-award-table');

    if ($myAwardsTable.length) {
        userStateManager.getUserProfileData(function (data) {
            if (!isNullUndefinedEmpty(data.ProfileInfo)) {
                if (data && data.apiProblemOccured) {
                    $myAwardsTable.parent().nextAll('.callout-box').remove();
                    $upcomingAwardsTable.remove();
                    displayErrorMessage($myAwardsTable.parent());
                    return;
                }

                if (!isNullUndefinedEmpty(data.ProfileInfo.Awards)) {
                    const fullListing = $myAwardsTable.data('full');
                    const max = 5;

                    //Format data ready to map to table
                    var awardsData = data.ProfileInfo.Awards.map((item) => {
                        return [item.Description];
                    });

                    if (fullListing) {
                        new AjaxTable($myAwardsTable, awardsData);
                    } else {
                        new AjaxTable($myAwardsTable, awardsData.slice(0, max));

                        //show a button if more than max
                        if (awardsData.length > max) {
                            $('.button-wrapper.my-awards-button-wrapper').css({
                                display: 'block',
                            });
                        }
                    }
                }

                if (!isNullUndefinedEmpty(data.ProfileInfo.EligibleAwards)) {
                    setupAwardsEligibleTable(data.ProfileInfo.EligibleAwards);
                }
            }
        });
    }

    function setupAwardsEligibleTable(eligibleAwards) {
        //Eligible Awards
        //Only renders the first item

        function getUpcomingAward(item) {
            return `
				<tr>
					<td>${item.Description}</td>
					<td>${moment(item.DateIssued).format('DD/MM/YYYY')}</td>
				</tr>
			`;
        }

        const firstAwardFormatted = moment(eligibleAwards[0].DateIssued).format('DD/MM/YYYY');
        var awardsOnSameDay = eligibleAwards.filter((award) => {
            return firstAwardFormatted === moment(award.DateIssued).format('DD/MM/YYYY');
        });

        var upcomingAwardHtml = awardsOnSameDay.map((item) => getUpcomingAward(item)).join('');

        $upcomingAwardsTable.find('tbody').html(upcomingAwardHtml);
    }
};
